import { createStore } from 'vuex'
import axios from "axios";
import router from "@/router";
import PaystackPop from '@paystack/inline-js'
const URL = "https://bekausebackend.onrender.com/";
export default createStore({
  state: {
    isLoading: false,
    isSent: {
      sent: false
    },
    project: null,
    web_stages: [
      {
        stage: "",
        description: ""
      },
      {
        stage: "Pre-production",
        description: "The process begins with gathering requirements, defining project goals, and planning the timeline. This phase ensures that our vision aligns with your expectations before any actual work starts. If needed, we'll schedule a meeting to go through these details together, ensuring complete clarity and alignment before moving forward."
      },
      {
        stage: "Initiate Design",
        description: "We’re ready to kick off the design process! To proceed with brainstorming, sketching, and conceptualizing the overall look and feel of the project, a design fee is required. Please note that the design process is a separate service, dedicated to bringing your ideas to life in a visual format. This phase is crucial in ensuring that the final product aligns with your vision."
      },
      {
        stage: "Awaiting payment",
        description: "We noticed that we haven't received your payment yet. Please double-check to ensure the payment was processed successfully. If you’ve already made the payment, kindly let us know so we can resolve any issues promptly. Thank you for your attention to this matter."
      },
      {
        stage: "Designing",
        description: "Detailed design work is underway, including the creation of wireframes, prototypes, and mockups. Feedback is essential during this phase to refine the design until it perfectly aligns with your vision."
      },
      {
        stage: "Design Complete",
        description: "The design phase is now finalized, with all visual elements approved and ready for development. The design is both aesthetically pleasing and functionally sound. To approve the design and initiate the development phase, a 50% deposit is required as a gesture of good business."
      },
      {
        stage: "Awaiting payment",
        description: "We noticed that we haven't received your payment yet. Please double-check to ensure the payment was processed successfully. If you’ve already made the payment, kindly let us know so we can resolve any issues promptly. Thank you for your attention to this matter."
      },
      {
        stage: "Developing",
        description: "Development is in full swing, transforming the design into a fully functional software product. This includes backend and frontend development, as well as integrating all necessary components. The development process will be split into 1-week sprints, with reviews scheduled on weekends to provide regular progress updates and make any necessary adjustments to keep everything on track."
      },
      {
        stage: "Review",
        description: "We have entered the review stage. Kindly utilize the provided link to thoroughly test both the appearance and functionality of the completed section."
      },
      {
        stage: "Developing (Continued)",
        description: "The remaining aspects of the project are being built, incorporating changes and improvements based on your feedback."
      },
      {
        stage: "Finalizing",
        description: "We have conducted extensive testing to ensure everything works as intended. Bugs, performance issues, and usability concerns have been addressed, and the product is now ready for deployment. Please make the final payment to initiate the deployment and migration process."
      },
      {
        stage: "Awaiting payment",
        description: "We noticed that we haven't received your payment yet. Please double-check to ensure the payment was processed successfully. If you’ve already made the payment, kindly let us know so we can resolve any issues promptly. Thank you for your attention to this matter."
      },
      {
        stage: "Deployment and Migration",
        description: "The software is being deployed to the production environment, with any necessary data migrated. Everything is being set up for smooth operation."
      },
      {
        stage: "Complete",
        description: "The project is now complete. The final product, along with any necessary documentation and support, is handed over, marking the successful conclusion of the development process."
      }
    ],
    ads_stages: [
      [
        {
          stage: "",
          description: ""
        },
        {
          stage: "Pre-production",
          description: "The process begins with gathering requirements, defining project goals, and planning the timeline. This phase ensures that our vision aligns with your expectations before any actual work starts. If needed, we'll schedule a meeting to go through these details together, ensuring complete clarity and alignment before moving forward."
        }, {
          stage: "Concept Development",
          description: "We’re ready to start developing the concept! To proceed with brainstorming, storyboarding, and conceptualizing the overall look and feel of the animation, a concept development fee is required. This phase is crucial in defining the visual style and narrative direction."
        },
        {
          stage: "Awaiting payment",
          description: "We noticed that we haven't received your payment yet. Please double-check to ensure the payment was processed successfully. If you’ve already made the payment, kindly let us know so we can resolve any issues promptly. Thank you for your attention to this matter."
        },
        {
          stage: "Designing",
          description: "Detailed design work is underway, including creation off all visual elements. Feedback is essential during this phase to refine the design until it perfectly aligns with your vision."
        },
        {
          stage: "Design Complete",
          description: "The design phase is now finalized, with all visual elements approved and ready for animation. The design is both aesthetically pleasing and functionally sound. To approve the design and initiate the animation phase, a 50% deposit is required."
        },
        {
          stage: "Awaiting payment",
          description: "We noticed that we haven't received your payment yet. Please double-check to ensure the payment was processed successfully. If you’ve already made the payment, kindly let us know so we can resolve any issues promptly. Thank you for your attention to this matter."
        },
        {
          stage: "Animation Production",
          description: "Animation is in full swing, bringing the designs to life. This includes creating keyframes, motion sequences, and integrating audio elements. The animation process will be split into 1-week sprints, with reviews scheduled on weekends to provide regular progress updates and make any necessary adjustments."
        },
        {
          stage: "Review",
          description: "We have entered the review stage. Kindly utilize the provided link to thoroughly watch and test both the appearance and functionality of the completed animation section."
        },
        {
          stage: "Animation (Continued)",
          description: "The remaining aspects of the animation are being completed, incorporating changes and improvements based on your feedback."
        },
        {
          stage: "Finalizing",
          description: "We have conducted extensive checks to ensure everything works as intended. Issues, performance concerns, and usability aspects have been addressed, and the animation is now ready for final delivery. Please make the final payment to proceed with the distribution."
        },
        {
          stage: "Awaiting payment",
          description: "We noticed that we haven't received your payment yet. Please double-check to ensure the payment was processed successfully. If you’ve already made the payment, kindly let us know so we can resolve any issues promptly. Thank you for your attention to this matter."
        },
        {
          stage: "Distribution",
          description: "The animation is being Distributed, along with any necessary documentation and support. Everything is being set up for smooth operation and final use."
        },
        {
          stage: "Complete",
          description: "The project is now complete. The final product, along with any necessary documentation and support, is handed over, marking the successful conclusion of the animation project."
        }
      ]
    ]
  },
  getters: {
    isSent(state) {
      return state.isSent
    },
    isLoading(state) {
      return state.isLoading
    },
    project(state) {
      return state.project
    },
    web_stages(state) {
      return state.web_stages
    },
    ads_stages(state) {
      return state.ads_stages
    }
  },
  mutations: {
    setSent(state, value) {
      state.isSent = value
    },
    setLoading(state, value) {
      state.isLoading = value
    },
    setProject(state, value) {
      state.project = value
    }
  },
  actions: {
    async sendMessage(context, payload) {
      context.commit("setLoading", true);
      let res = await axios.post(`${URL}message`, payload);
      let { result, msg, err } = await res.data;
      if (!(err == undefined)) {
        context.commit("setLoading", false);

      } else {
        context.commit("setLoading", false);
        let val = {
          sent: true
        }
        context.commit("setSent", val)
      }
    }, async wake(context) {
      try {

        let res = await fetch(`${URL}project/000000`,);
        let data = await res.json();

      } catch (e) {

      }
    },
    async fetchProject(context, payload) {
      try {
        context.commit("setLoading", true);
        let res = await fetch(`${URL}project/${payload.track_code}`,);
        let data = await res.json();
        context.commit("setProject", data.results[0])
        context.commit("setLoading", false);
        sessionStorage.setItem('tracked', payload.track_code)
      } catch (e) {
        context.commit("setLoading", false);
      }
    },
    async keepProject(context) {

      if (!(this.state.project)) {
        if (sessionStorage.getItem('tracked')) {
          context.commit('setLoading', true)
          try {
            let res = await fetch(`${URL}project/${sessionStorage.getItem('tracked')}`,);
            let data = await res.json();
            context.commit("setProject", data.results[0])
            context.commit('setLoading', false)

          } catch (e) {
            context.commit('setLoading', false)
          }
        }

      }
    },
    async skipDevStatus(context) {
      context.commit("setLoading", true);
      let res = await axios.put(`${URL}devStatusSkip/${this.state.project?.project_id}`);
      context.commit('setProject', null)
      let { result, msg, err } = await res.data;
      if (!(err == undefined)) {
        context.commit("setLoading", false);

      } else {
        context.commit("setLoading", false);
        context.dispatch('keepProject')
      }
    },
    async nextDevStatus(context) {
      context.commit("setLoading", true);
      let res = await axios.put(`${URL}devStatusNext/${this.state.project?.project_id}`);
      context.commit('setProject', null)
      let { result, msg, err } = await res.data;
      if (!(err == undefined)) {
        context.commit("setLoading", false);

      } else {
        context.commit("setLoading", false);
        context.dispatch('keepProject')
      }
    },
    async initPayment() {

      console.log("works")
      try {
        console.log("works 2")
        let res = await fetch(`${URL}initPayment`,);
        let data = await res.json();
        console.log("works 3")
        console.log(data)
      } catch (e) {

      }

    },
    async makePayment(context) {
      let handler = PaystackPop.setup({
        key: 'pk_live_a0401c6a28a58205de642def47c63322fc7399c8', // Replace with your public key
        email: this.state.project?.client_email,
        amount: (this.state.project?.price / 2) * 100,
        currency: 'ZAR',
        ref: '' + Math.floor((Math.random() * 1000000000) + 1), // generates a pseudo-unique reference. Please replace with a reference you generated. Or remove the line entirely so our API will generate one for you
        // label: "Optional string that replaces customer email"
        onClose: function () {
          alert('Window closed. Payment failed');
        },
        callback: function (response) {

          context.dispatch('skipDevStatus')
        }
      });

      handler.openIframe();

    },
    async designPayment(context) {
      let handler = PaystackPop.setup({
        key: 'pk_live_a0401c6a28a58205de642def47c63322fc7399c8', // Replace with your public key
        email: this.state.project?.client_email,
        amount: 200 * 100,
        currency: 'ZAR',
        ref: '' + Math.floor((Math.random() * 1000000000) + 1), // generates a pseudo-unique reference. Please replace with a reference you generated. Or remove the line entirely so our API will generate one for you
        // label: "Optional string that replaces customer email"
        onClose: function () {
          alert('Window closed. Payment failed');
        },
        callback: function (response) {

          context.dispatch('skipDevStatus')
        }
      });

      handler.openIframe();

    }, async conceptPayment(context) {
      let handler = PaystackPop.setup({
        key: 'pk_live_a0401c6a28a58205de642def47c63322fc7399c8', // Replace with your public key
        email: this.state.project?.client_email,
        amount: 150 * 100,
        currency: 'ZAR',
        ref: '' + Math.floor((Math.random() * 1000000000) + 1), // generates a pseudo-unique reference. Please replace with a reference you generated. Or remove the line entirely so our API will generate one for you
        // label: "Optional string that replaces customer email"
        onClose: function () {
          alert('Window closed. Payment failed');
        },
        callback: function (response) {

          context.dispatch('skipDevStatus')
        }
      });

      handler.openIframe();

    }

  },
  modules: {
  }
})
