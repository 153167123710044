<template>
  <div class="intro phone" id="intro" >
    <h2>
      Empowering Start-up and Corporate Businesses in the Digital Age!
    </h2>
    <h6>
      Elevating Enterprises Through Tailored Web Development, Design<br />
      and Digital Marketing Solutions.
    </h6>
    <div class="buttons">
      <router-link to="enquire" class="button black">
        <p>Get started</p>
      </router-link>
      <router-link to="track" class="button green">
        <p>Track</p>
      </router-link>
    </div>
  </div>
  <div class="intro pc" id="intro">
    <div class="text">
      <h2>
        Empowering Start-up <br>and Corporate Businesses<br />in the Digital Age!
      </h2>
      <h6>
        Elevating Enterprises Through Tailored Web Development, Design<br />
        and Digital Marketing Solutions.
      </h6>
      <div class="buttons">
        <router-link to="enquire" class="button black">
          <p>Get started</p>
        </router-link>
        <router-link to="track" class="button green">
          <p>Track</p>
        </router-link>
      </div>
    </div>
    <img src="../assets/Female developer.svg" alt="">
  </div>
</template>
<script>
export default {};
</script>
<style>
@media screen and (max-width: 520px) {
  .phone {
    background-image: url(../assets/Background\ img.svg);
    background-position: bottom center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
  }

  .phone .buttons {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    align-items: center;
    justify-content: center;
  }
  .phone .buttons .button {
    text-decoration: none;
    width: 7rem;
    height: 2.3rem;
    border: none;
    border-radius: 0.3rem;
    display: flex;
    place-items: center;
  }
  .phone .buttons p {
    padding: 0;
    margin: 0;
    font-weight: 600;
    margin: auto;
  }
  .black {
    background-color: black;
    color: #f5f5f5;
  }
  .green {
    background-color: #16ca73;
    color: White;
  }
  .pc {
    display: none;
  }
}
@media screen and (min-width: 521px) {
  .intro {
    text-align: left;
    display: flex;
    
    align-items: center;
    gap: 1rem;
    padding-left: 6rem;
    padding-right: 6rem;
    position: relative;
  }
  .pc .text {
    display: flex;
    flex-direction: column;
    gap: 1.8rem;
    align-items: flex-start;
  }

  #app .intro h2 {
    font-size: 3rem;
  }
  #app .intro h6 {
    font-size: 1.1rem;
    line-height: 1.3rem;
  }

  .pc .buttons {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    align-items: center;
    justify-content: center;
  }
  .pc .buttons .button {
    text-decoration: none;
    width: 7rem;
    height: 2.3rem;
    border: none;
    border-radius: 0.3rem;
    display: flex;
    place-items: center;
  }
  .pc .buttons p {
    padding: 0;
    margin: 0;
    font-weight: 600;
    margin: auto;
  }
  .black {
    background-color: black;
    color: #f5f5f5;
  }
  .green {
    background-color: #16ca73;
    color: White;
  }
  .phone {
    display: none;
  }
  .pc img{
    width: 34vw;
    position: absolute;
    right: 10vw;
    bottom: 0;
  }
}
</style>
