<template>
  <div class="services" id="services">
    <h3 class="underlined">
      What we offer
      <div class="underline"></div>
    </h3>
    <h3 class="provide">We provide digital and software services <br>to help businesses grow</h3>

    <div class="servs">
      <div class="serv">
        <img src="../assets/GraphDe.png" alt="Graphic design logo" />
        <h5>Branding & Graphic designing</h5>
        <p>
          Unlock the full potential of your business with our extraordinary
          graphic design services. From captivating posters to distinctive logo
          designs, we create visually stunning solutions that drive unparalleled
          traffic and success for your brand.
        </p>
      </div>
      <div class="serv">
        <img src="../assets/WebDev.png" alt="Web development logo" />
        <h5>Software & Web development</h5>
        <p>
          Elevate your online presence with a meticulously crafted website that
          seamlessly combines user-friendliness and captivating design, ensuring
          an exceptional browsing experience for your visitors.
        </p>
      </div>
      <div class="serv">
        <img src="../assets/MoGraph.png" alt="Motion graphics logo" />
        <h5>Ads and Motion graphics</h5>
        <p>
          From unique logo reveals to captivating animated ads. Create Motion
          Graphics to effectively promote your company, effortlessly captivating
          viewers and leaving a lasting impression.
        </p>
      </div>
    </div>
  </div>
</template>
<script>
export default {};
</script>
<style>
@media screen and (max-width: 520px) {
  #app .services {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    background-color: black;
    color: #ffffff;
    height: fit-content;
  }
  .services .servs {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1.5rem;
  }
  .services .servs .serv {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  .services .servs img {
    height: 3.5rem;
    object-fit: contain;
    width: fit-content;
    filter: invert(100%) sepia(2%) saturate(229%) hue-rotate(239deg)
      brightness(113%) contrast(92%);
  }
  #app .services .underlined {
    position: relative;
    padding-bottom: 0.5rem;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
  }
  .services .servs h5 {
    object-fit: contain;
    width: fit-content;
    text-align: left;
  }
  .services .servs p {
    object-fit: contain;
    width: fit-content;
    text-align: left;
  }
  .underline {
    width: 100%;
    height: 0.2rem;
    border-radius: 0.1rem;
    background-color: #16ca73;
    margin-left: auto;
    margin-right: auto;
    position: absolute;
    bottom: 0;
  }
  .provide {
    display: none;
  }
}
@media screen and (min-width:521px) {
  #app .services .servs h5 {
    font-size: 1rem;
    object-fit: contain;
    width: fit-content;
    text-align: left;
  }
  #app .services .servs p {
    font-size: 0.8rem;
    object-fit: contain;
    width: fit-content;
    text-align: left;
  }
  #app .services {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-top: 8rem;
  }
  .services .servs img {
    height: 3rem;
    object-fit: contain;
    width: fit-content;
  }
  .services .servs {
    padding: 3rem 6rem;
    display: flex;
    gap: 6rem;
    margin-top: 4rem;
  }
  .services .servs .serv{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: flex-start;

  }
  .underlined {
    display: none;
  }
  h3 {
    text-align: left;
  }
}
</style>
