<template>
  <div class="home">
    <Intro />
    <Services />
    <Why />
    <Rates />
  </div>
</template>

<script>
// @ is an alias to /src
import HelloWorld from "@/components/HelloWorld.vue";
import Intro from "@/components/Intro.vue";
import Services from "@/components/Services.vue";
import Why from "@/components/Why.vue";
import Rates from "@/components/Rates.vue";
export default {
  name: "HomeView",
  components: {
    HelloWorld,
    Intro,
    Services,
    Why,
    Rates,
  },
  methods: {
    checkLoc() {
      let value = sessionStorage.getItem("sent");
      if (value !== null) {
      } else {
        sessionStorage.setItem("sent", JSON.stringify(false));
      }
    },
  },
  created() {
    this.checkLoc();
  },
};
</script>
<style>
@media screen and (max-width: 520px) {
  .home {
    display: flex;
    flex-direction: column;
    width: 100vw;
    min-height: 100vh;
    background-color: #f7fbf9;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
  }
  .home > div {
    min-height: 100vh;
    width: 100vw;
    padding: 4rem 1rem 1.5rem 1rem;
  }
  .home > div h2 {
    font-weight: 600;
    font-size: 1.6rem;
    margin-top: 10%;
  }
  .home > div h3 {
    font-weight: 600;
    font-size: 1.4rem;
    margin-top: 1rem;
  }
  .home > div h5 {
    font-weight: 600;
    font-size: 1rem;
    margin: 0;
  }
  .home > div h6 {
    font-weight: 400;
    font-size: 0.8rem;
    line-height: 1rem;
  }
  .home > div p {
    font-weight: 500;
    font-size: 0.7rem;
  }
  .home > div .button > p {
    font-weight: 600;
    font-size: 0.8rem;
  }
}
@media screen and (min-width: 521px) {
  .home {
    display: flex;
    flex-direction: column;
    width: 100vw;
    min-height: 100vh;
    background-color: #f7fbf9;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
  }
  .home > div {
    min-height: 100vh;
    width: 100vw;
    padding: 4rem 10rem 1.5rem 10rem;
  }
  .home > div h2 {
    font-weight: 600;
    font-size: 2.6rem;
    
  }
  .home > div h3 {
    font-weight: 600;
    font-size: 2.2rem;
    margin-top: 1rem;
  }
  .home > div h5 {
    font-weight: 600;
    font-size: 1.2rem;
    margin: 0;
  }
  .home > div h6 {
    font-weight: 400;
    font-size: 1rem;
    line-height: 1rem;
  }
  .home > div p {
    font-weight: 500;
    font-size: 0.9rem;
  }
  .home > div .button > p {
    font-weight: 600;
    font-size: 0.8rem;
  }
}
</style>
