<template>
  <div class="loader" v-if="isLoading">
    <img src="../assets/logo.png" alt="">
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters(['isLoading'])
  }
};
</script>
<style>
.loader {
  position: absolute;
  z-index: 6;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: #f5f5f5;
  display: flex;
  justify-content: center;
  align-items: center;
}
.loader img{
  width: 3.5rem;
  animation: pulse 1s ease-in-out infinite;
}
@keyframes pulse {
  0% {
    scale: 1;
    opacity: 0.3;
  }
  50% {
    scale: 1.1;
    opacity: 1;
  }
  100% {
    scale: 1;
    opacity: 0.3;
  }
}
</style>
