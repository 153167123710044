<template lang="">
  <nav class="navbar navbar-expand-lg">
    <div class="container-fluid">
      <a class="navbar-brand" href="#intro">
        <img src="../assets/bekause.png" alt="bekause logo" />
      </a>
      
      <button
        class="navbar-toggler"
        data-bs-toggle="collapse"
        data-bs-target="#navbarNavAltMarkup"
        aria-controls="navbarNavAltMarkup"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <img src="../assets/Menu.png" alt="menu" />
      </button>
      <div class="collapse navbar-collapse" id="navbarNavAltMarkup">
        <div class="navbar-nav">
          <a class="nav-link" href="#services">Services</a>
          <a class="nav-link" href="#why">Why Us?</a>
          <a class="nav-link" href="#pricing">Pricing</a>
        </div>
      </div>
    </div>
  </nav>
</template>
<script>
export default {
  name: "Navbar",
};
</script>
<style>
@media screen and (max-width: 520px) {
  #app nav {
    position: absolute;
    z-index: +2;
    padding: 0.5rem 0;
    background: none;
    display: block;
    place-items: center;
    top: 0;
    width: 100vw;
  }
  nav > div {
    display: flex;
    justify-content: space-between;
  }
  nav > div > a > img {
    height: 1.2rem;
  }
  nav .navbar-toggler {
    border: none;
    outline: none;
    background: none;
  }
  #app nav .navbar-toggler:active,
  #app nav .navbar-toggler,
  #app nav .navbar-toggler:focus {
    border: none;
    outline: none;
    background: none;
    box-shadow: none;
    padding: 0;
    margin: 0;
  }
  .navbar-toggler img {
    height: 1.2rem;
  }
  .collapse {
    background-color: #f7fbf9;
  }
}
@media screen and (min-width: 521px){
  #app nav {
    position: sticky;
    z-index: +2;
    padding: 0.5rem 2rem;
    background: none;
    display: block;
    place-items: center;
    top: 0;
    width: 100vw;
    background-color: #f7fbf9;
  }
  nav > div {
    display: flex;
    justify-content: space-between;
  }
  .navbar-nav {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 2rem;
  }
  #app .navbar-nav a{
    font-size: 0.9rem;
    padding: 0;
    padding-top: 0.3rem;
  }
  nav > div > a > img {
    height: 1.2rem;
  }
  .collapse {
    margin-left: 2rem;
  }
}
</style>
