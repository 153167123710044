<template>
  <Navbar/>
  <router-view />
  <Loader />
</template>
<script>
// @ is an alias to /src
import Navbar from '@/components/Navbar.vue'
import Loader from '@/components/Loader.vue'
export default {
  name: "HomeView",
  components: {
    Navbar,
    Loader
  },
};
</script>
<style>
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: normal;
  background-color: #e9e9e9;
  color: black;
  position: relative;
  height: 100vh;
  max-height: 100vh;
  max-width: 100vw;
  overflow: scroll;
  scroll-behavior: smooth;
}
@media screen and (min-width:521px){
  #app{
    overflow-y: auto;
    overflow-x: hidden;
  }
}
</style>
