<template>
  <div class="why" id="why">
    <h3>Why are we <br />the best option</h3>

    <div class="reasons">
      <div class="reason reason1">
        <img src="../assets/Track.svg" alt="" />
        <h5>
          Live development progress tracking
          <p>
            We keep you updated on the status of your projects development by
            providing a live development status update on our website
          </p>
        </h5>
      </div>
      <div class="reason reason2">
        <img src="../assets/Action.svg" alt="" />
        <h5>
          Make all crucial actions on the app

          <p>
            All decisions and actions that would usually require a meeting can
            be simply done on the app.
          </p>
          <ul>
            <li>Initiate a project</li>
            <li>View & approve design</li>
            <li>Schedule meetings</li>
            <li>Make payments</li>
          </ul>
        </h5>
      </div>
      <div class="reason reason3">
        <img src="../assets/Meeting.svg" alt="" />
        <h5>
          We do meetings modern style
          <p>
            Say goodbye to conventional meetings. Let's discuss the brief and go
            over the design over a cup of coffee at your nearest coffee shop, on
            us of course =)
          </p>
        </h5>
      </div>
    </div>
  </div>
</template>
<script>
export default {};
</script>
<style>
@media screen and (max-width: 520px) {
  .why img {
    width: 95%;
  }
 
  .why .reason {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }
  .why .reason ul {
    font-weight: 400;
    font-size: 0.7rem;
  }
}
.why .reasons {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5rem;
  }
.why {
  color: black;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: flex-start;
}

.why h3 {
  text-align: left;
}
.why h5 {
  text-align: left;
}
#app .why h5 p {
  text-align: left;
  margin-top: 0.2rem;
  font-weight: 400;
  margin-top: 0.5rem;
}

.why .reason li::marker {
  color: #16ca73;
}
@media screen and (min-width:521px){
  .why img {
    width: 25vw;
  }
  .why h5 {
    max-width: 30vw;
  }
 
  .why .reason {
    display: flex;
    flex-direction: row;
    gap: 4rem;
    align-items: center;
  }
  .why .reason ul {
    font-weight: 400;
    font-size: 0.7rem;
  }
  .why .reasons {
    width: 100%;
    gap: 8rem;
  }
  .why .reason1{
    flex-direction: row-reverse;
    margin-top: 8rem;
  }
  .why .reason3 {
    flex-direction: row-reverse;
    margin-bottom: 12rem;
  }
}
</style>
