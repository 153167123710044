<template>
  <div class="pricing" id="pricing">
    <h3>Take a look at our <br />affordable rates</h3>
    <div class="rates-content">
      <div class="rates">
        <div class="rate r1">
          <h4>Branding & Graphic designing</h4>
          <img src="../assets/Poster.svg" alt="" />
          <p class="small">From</p>
          <h5 class="big">R 300</h5>
        </div>
        <div class="rate r2">
          <h4>Software & Web development</h4>
          <img src="../assets/Code.svg" alt="" />
          <p class="small">From</p>
          <h5 class="big">R 900</h5>
        </div>
        <div class="rate r3">
          <h4>Ads & Motion graphics</h4>
          <img src="../assets/Ad.svg" alt="" />
          <p class="small">From</p>
          <h5 class="big">R 500</h5>
        </div>
      </div>
      <div class="quote">
        <p>
          These are our starting rates. For a customized quote tailored to your
          project, feel free to reach out and send us a message.
        </p>
        <router-link to="enquire" class="button green">
          <p>Get a quote</p>
        </router-link>
      </div>
    </div>
    <div class="footer">
      <div class="logo">
        <img src="../assets/bekause.png" alt="" />
      </div>
      <div class="rights">
        <h5>&copy2024 Bekause Pty(Ltd)</h5>
        <h5>All rights reserved</h5>
      </div>
      <div class="rights">
        <h5>help@bekause.co.za</h5>
        <h5>0638973295</h5>
      </div>
      <router-link to="terms"><h5>T's & C's</h5></router-link>
    </div>
  </div>
</template>
<script>
export default {};
</script>
<style>
#app .pricing {
  display: flex;
  flex-direction: column;
  padding-top: 8%;
  position: relative;
}
.pricing {
  color: #ffffff;
  background-color: black;
}
.rates {
  display: flex;
  align-items: center;
  margin-top: 8%;
}
.rate {
  max-width: 30%;
  padding: 0.5rem;
  height: 12rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
}
.r1,
.r3 {
  background-color: #16ca73;
}
.r1 {
  padding: 0;
  padding-left: 0.8rem;
  padding: 0.5rem 0 0.5rem 0.8rem;
}
.r3 {
  padding: 0;
  padding: 0.5rem 0.8rem 0.5rem 0;
}
.r2 {
  background-color: #ffffff;
  color: black;
  max-width: 40%;
  height: 13rem;
}
.rates h4 {
  font-size: 0.8rem;
  font-weight: 400;
}
#app .small {
  font-size: 0.6rem;
  padding: 0;
  margin: 0;
  line-height: 0.7rem;
}
.rate img {
  height: 5rem;
  object-fit: contain;
}
.footer {
  background-color: #ffffff;
  width: 100vw;
  position: absolute;
  bottom: 0;
  left: 0;
  color: black;
  height: 12rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  padding: 1rem;
  gap: 0.5rem;
}
.footer img {
  height: 0.8rem;
}
.footer a {
  text-decoration: none;
  color: #9dbd3c;
}
#app .footer h5 {
  font-size: 0.8rem;
  font-weight: 300;
}
.button {
  text-decoration: none;
  width: 7rem;
  height: 2.3rem;
  border: none;
  border-radius: 0.3rem;
  display: flex;
  place-items: center;
}
.button p {
  padding: 0;
  margin: 0;
  font-weight: 600;
  margin: auto;
}
.black {
  background-color: black;
  color: #f5f5f5;
}
.green {
  background-color: #16ca73;
  color: White;
}
@media screen and (max-width: 520px) {
  #app .rates-content .quote {
    margin-top: 2rem;
  }
  .button {
    margin-left: auto;
    margin-right: auto;
  }
}
@media screen and (min-width: 521px) {
  #app .rates-content {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: center;
    margin-top: 5rem;
    gap: 5rem;
  }
  #app .rates {
    width: fit-content;
    margin: 0;
  }
  #app .rates-content .quote {
    width: 30vw;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  #app .pricing {
    padding-top: 5%;
  }
}
</style>
